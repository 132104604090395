import { graphql, Link } from 'gatsby';
import React from 'react';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Quote from '../components/Quote';
import Section from '../components/Section';
import Video from '../components/Video';
import '../styles/templates/_student.scss';

const baseUrl = '/annual-challenge/finalists-mentors-judges';
const Participant = ({ data: { student } }) => {
  const mentor = student.mentor;

  return (
    <Layout
      title={`Student Alumni: ${student.firstName} ${student.lastName} | ${student.tag}`}
      className={`${student.firstName}-${student.lastName} ${student.tag} annual-challenge finalists-mentors-judges student`}
    >
      {/* Student Title & Video */}
      <Section>
        <Container>
          {/* Breadcrumbs */}
          <ul className="breadcrumbs no-hero">
            <li className="breadcrumb">
              <Link to="/annual-challenge/about-the-challenge/">
                Annual Challenge
              </Link>
            </li>
            <li className="breadcrumb">
              <Link to={`${baseUrl}#innovators-anchor`}>
                Finalists, Mentors and Judges
              </Link>
            </li>
            <li className="breadcrumb active">
              {student.firstName} {student.lastName}
            </li>
          </ul>
          <Row>
            <Column size={10} offset={1} className="no-hero">
              <h1>
                Innovative Minds: {student.firstName} {student.lastName}
              </h1>
              {/* Video Container */}
              <div className="video-container__with-pager">
                <Video
                  youTubeID={student.youtubeVideo}
                  guidSrc={student.guidVideo}
                />
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      {/* Student Bio & Project Info */}
      <Section>
        <Container className="student-info">
          <Row>
            <Column size={7} offset={1}>
              <p className="student-info__demographics">
                {student.schoolName} | {student.schoolGrade} grade |{' '}
                {student.age} years old
              </p>
              <h2 className="student-info__project-title">
                {student.ideaName.map((ideaName, i) => (
                  <span key={i}>{ideaName}</span>
                ))}
              </h2>
              <h3 className="student-info__project-about">About the Idea</h3>
              {student.aboutIdea.map((idea, i) => (
                <p key={i}>{idea}</p>
              ))}
              {/*<h4>{student.tag}</h4>*/}
              <h4>Why did you enter the 3M Young Scientist Challenge?</h4>
              {student.challengeMotivation.map((motive, i) => (
                <p key={i}>{motive}</p>
              ))}
              <h4>
                What is your favorite invention of the last 100 years, and why?
              </h4>
              {student.favoriteInvention.map((invention, i) => (
                <p key={i}>{invention}</p>
              ))}
              <h4>In 15 years I hope to be...</h4>
              {student.dreamOcupation.map((paragraph, i) => (
                <p key={i}>{paragraph}</p>
              ))}
            </Column>
            <Column size={4}>
              <Quote image={student.quoteImage} text={student.quote} />
            </Column>
          </Row>
        </Container>
      </Section>
      {/* Meet the Mentor */}
      {mentor && (
        <Section>
          <Container className="student-mentor">
            <h2>Meet the Mentor</h2>
            <Row>
              <Column size={4}>
                <Image filename={mentor.cardImage} />
              </Column>
              <Column size={5}>
                <div>
                  <h3 className="student-mentor__name">
                    {mentor.prefix} {mentor.name}
                  </h3>
                  <h4 className="student-mentor__job-title">
                    {mentor.jobTitle}
                  </h4>
                  <p className="card">
                    {mentor.shortDescription.map((paragraph, i) => (
                      <div>{paragraph}</div>
                    ))}
                  </p>
                  <Link
                    to={`/annual-challenge/finalists-mentors-judges/mentors/${mentor.slug}`}
                    className="cta__link no-arrow bg-gradient-orange"
                    style={{ position: 'relative' }}
                  >
                    Learn More
                    <Icon name="arrowright" className="ml-0.5" />
                  </Link>
                </div>
              </Column>
            </Row>
          </Container>
        </Section>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    student: studentsYaml(slug: { eq: $slug }) {
      aboutIdea
      age
      challengeMotivation
      cardImage
      flag
      dreamOcupation
      favoriteInvention
      ideaName
      mentor {
        prefix
        name
        jobTitle
        cardImage
        shortDescription
        slug
      }
      quote
      quoteImage
      schoolGrade
      schoolName
      youtubeVideo
      guidVideo
      firstName
      lastName
      tag
    }
  }
`;

export default Participant;
